/* Education.css */

.education {
  padding: 2%;
  width: auto;
  height: auto;
  text-align: left;
  background-color: rgba(197, 200, 201, 0.798);
}

.education h2 {
  font-size: 3rem;
  margin-bottom: auto;
  font-weight: bold;
}

.education h3 {
  font-size: 2rem;
  margin-bottom: auto;
  font-weight: bold;
}

.education p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.education ul {
  list-style-type: circle;
  padding: auto;
}

.education li {
  font-size: 1.2rem;
  text-underline-position: below;
  margin-bottom: auto;
}

/* Dark mode styles */
.dark-mode .education {
  background-color: #444444;
}

.dark-mode .education h2,
.dark-mode .education h3,
.dark-mode .education p,
.dark-mode .education ul {
  color: rgb(219, 219, 219);
}

.resume-button {
  margin-top: 25px; /* Adjust the margin as needed */
  text-align: left;
}

.resume-button button {
  padding: 15px 30px; /* Adjust padding as needed */
  background-color: #8ebcec; /* Default button color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 1%;
}

.resume-button button:hover {
  background-color: #007bff; /* Duller color on hover */
  transform: scale(1.1); /* Scale up the button on hover */
}

/* Media query for mobile screens */
@media only screen and (max-width: 800px) {
  .education {
    padding-left: 5%;
    padding-top: 5%;
  }

  .education h2 {
    font-size: 1.5rem; /* Adjust heading font size for smaller screens */
  }

  .education h3 {
    font-size: 1rem; /* Adjust heading font size for smaller screens */
  }

  .education p {
    font-size: 0.7rem; /* Adjust paragraph font size for smaller screens */
    font-weight: lighter;
  }

  .education ul li {
    font-size: 0.7rem; /* Adjust paragraph font size for smaller screens */
    font-weight: lighter;
  }
}
