/* General Styles */
.game-container {
  font-family: 'Arial', sans-serif;
  color: #222;
  background-color: #f5f5f5;
  padding: 0 20px;
}

.game-header {
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(to right, #f39c12, #d35400);
  color: #fff;
}

.game-header h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.game-header p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Project Overview */
.game-overview {
  text-align: center;
  margin: 50px auto;
  max-width: 800px;
}

.game-overview h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #e67e22;
}

/* Image Gallery */
.game-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 50px 0;
}

.gallery-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.gallery-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #e74c3c;
}

.gallery-item img {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.gallery-item p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Code Section */
.game-code {
  text-align: center;
  margin: 50px auto;
  max-width: 800px;
}

.game-code h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #3498db;
}

.game-code img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.btn-link {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  background-color: #2980b9;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.btn-link:hover {
  background-color: #1a5276;
}

/* Footer */
.game-footer {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  font-size: 0.9rem;
}
