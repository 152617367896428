/* General Styles */
.semantic-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.header {
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #fff;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.header p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Zigzag Section */
.zigzag-section {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 20px;
}

.zigzag-block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
}

.zigzag-block.reverse {
  flex-direction: row-reverse;
}

.zigzag-text {
  flex: 1;
  padding: 20px;
}

.zigzag-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #007bff;
}

.zigzag-text p {
  font-size: 1rem;
  line-height: 1.5;
}

.zigzag-image {
  flex: 1;
  text-align: center;
}

.zigzag-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-link {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.btn-link:hover {
  background-color: #0056b3;
}

/* Footer */
.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  font-size: 0.9rem;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .header p {
    font-size: 1rem;
  }

  .zigzag-block {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .zigzag-block.reverse {
    flex-direction: column;
  }

  .zigzag-text {
    padding: 10px;
  }

  .zigzag-text h2 {
    font-size: 1.8rem;
  }

  .zigzag-text p {
    font-size: 0.9rem;
  }

  .zigzag-image img {
    max-width: 90%;
  }

  .btn-link {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
