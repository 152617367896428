.projects {
  padding: 4%;
  background-color: rgb(250, 250, 250);
}

.projects h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #333;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two-column layout */
  gap: 1.5rem; /* Space between cards */
}

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.project-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.project-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
  object-fit: cover;
  filter: blur(10px); /* Initial blur effect */
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.project-card:hover .project-image img {
  filter: blur(0); /* Remove blur on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.project-overlay h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.details-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.details-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.collaborate-section {
  text-align: center;
  margin-top: 3rem;
}

.collaborate-section p {
  font-size: 1.2rem;
  color: #555;
}

.collaborate-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.collaborate-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.dark-mode .collaborate-section p {
  color: #ddd;
}

.dark-mode .collaborate-button {
  background-color: #1e90ff;
}

.dark-mode .collaborate-button:hover {
  background-color: #6495ed;
}

/* Dark mode styles */
.dark-mode .projects {
  background-color: #444;
}

.dark-mode .projects h2 {
  color: #fff;
}

.dark-mode .project-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.dark-mode .details-button {
  background-color: #1e90ff;
}

.dark-mode .details-button:hover {
  background-color: #6495ed;
}

/* Responsive Design */
@media only screen and (max-width: 800px) {
  .projects-grid {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .project-card {
    border-radius: 8px;
  }

  .project-overlay h3 {
    font-size: 1.2rem;
  }

  .details-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
