/* Home.css */

.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home.dark-mode {
  background-image: url('./images/cloud_dark.png');
}

.home.light-mode {
  background-image: url('./images/cloud.png');
}

.home-container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.home-info {
  text-align: justify;
  padding-left: 5%;
}

.hello-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative; /* Added position relative */
}

.wave-hand {
  display: inline-block;
  position: relative; /* Added position relative */
  animation: wave 1s infinite alternate;
  transform-origin: bottom;
  cursor: pointer;
}

@keyframes wave {
  0%,
  100% {
    transform: scale(1) rotate(0deg); /* Initial and final position */
  }
  25% {
    transform: scale(1.2) rotate(20deg); /* Hand expands and rotates */
  }
  50% {
    transform: scale(1) rotate(0deg); /* Hand back to normal */
  }
  75% {
    transform: scale(1.2) rotate(-20deg); /* Hand expands and rotates */
  }
}

.popup {
  position: fixed;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.23);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.popup p {
  color: #000000;
  margin: 0;
  font-weight: lighter;
}

h1 {
  font-size: 2.9rem;
  font-weight: bold;
}

p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0;
}

.home-typing-effect {
  font-size: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid transparent; /* Adjust or remove this property */
  animation: typing 15s linear infinite, blink-caret 0.5s infinite alternate;
  color: blue;
  height: 2rem; /* Adjust the height to fit within mobile screens */
}

@keyframes typing {
  0%,
  100% {
    width: 0;
  }
  50% {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #ffffff00;
  }
}

.home h2 {
  font-size: 1.5rem;
  font-weight: lighter;
  color: rgb(255, 255, 255);
}

.home.light-mode h2 {
  font-size: 1.5rem;
  font-weight: lighter;
  color: #000; /* Set color to black */
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  12% {
    transform: translateY(-10px);
  }
  25% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(20px);
  }
  75% {
    transform: translateY(-17px);
  }
  80% {
    transform: translateY(-8px);
  }
  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply shake animation to the image container when shaking is active */
.image-container.shake-animation {
  animation: shake 0.5s ease-in-out;
}

.social-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0px;
}

.social-buttons a {
  margin-right: 1px;
  font-size: 30px;
  color: inherit;
  transition: transform 0.2s ease-in-out;
}

.social-buttons a:hover {
  transform: scale(1.2);
}

.social-buttons a:last-child {
  margin-right: 0px;
}

.resume-button {
  margin-top: 25px; /* Adjust the margin as needed */
  text-align: left;
}

.resume-button button {
  padding: 15px 30px; /* Adjust padding as needed */
  background-color: #8ebcec; /* Default button color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 1%;
}

/* Dark mode button style */
.home.dark-mode .resume-button button {
  background-color: rgba(0, 0, 0, 0.596); /* Button color in dark mode */
  color: #ffffff; /* Text color in dark mode */
  border: 1px solid #ffffff;
}

.resume-button button:hover {
  background-color: #007bff; /* Duller color on hover */
  transform: scale(1.1); /* Scale up the button on hover */
}

.home.dark-mode .resume-button button:hover {
  background-color: white; /* Button color in dark mode */
  color: #000000; /* Text color in dark mode */
  border: 1px solid #000000;
}

.developer-image {
  max-width: 100%;
  height: auto;
}

.shake {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Adjust image size for social buttons */
.social-buttons a img {
  width: 40px; /* Adjust the width to your desired size */
  height: auto; /* Maintain aspect ratio */
}

/* Media query for mobile screens */
@media only screen and (max-width: 800px) {
  .home-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Center the text horizontally */
    margin-right: 5%; /* Add a margin to the right side */
  }

  .hello-text {
    align-items: center; /* Center the text horizontally */
    flex-direction: column; /* Arrange text in a column */
  }

  h1 {
    font-size: 5vw; /* Adjust font size */
    line-height: 1.2; /* Increase line height for better readability */
    max-width: 90%; /* Limit width to ensure it fits within the screen */
    margin: 0 auto; /* Center the element horizontally */
    margin-bottom: 10px;
  }

  p {
    font-size: 3vw; /* Adjust font size */
    line-height: 1.2; /* Increase line height for better readability */
    max-width: 90%; /* Limit width to ensure it fits within the screen */
    margin: 0 auto; /* Center the element horizontally */
    margin-bottom: 10px;
  }

  .home-typing-effect {
    font-size: x-small;
    height: 1rem; /* Adjust the height to fit within mobile screens */
  }

  .home h2 {
    font-size: 1rem;
    font-weight: lighter;
  }

  .home.light-mode h2 {
    font-size: 1rem;
    font-weight: lighter;
  }

  .social-buttons a img {
    width: 20px; /* Adjust the width to your desired size */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px;
  }

  .resume-button button {
    font-size: 14px; /* Adjust font size */
    padding: 6px 14px; /* Adjust padding */
  }

  .image-container {
    display: none;
  }
}
