.resume-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background to create a modal effect */
  backdrop-filter: blur(5px); /* Apply blur effect to the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the popup is on top of other content */
}

.resume-popup-content {
  background-color: #ffffff38; /* Background color of the popup content */
  border-radius: 20px; /* Rounded corners for the popup */
  width: 90%; /* Adjusted width */
  height: 90%; /* Adjusted height */
  max-width: 90%; /* Maximum width of the popup content */
  max-height: 90%; /* Maximum height of the popup content */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  position: relative; /* Added position relative */
  display: flex; /* Added display flex */
  flex-direction: column; /* Added flex direction column */
  align-items: center; /* Added align items center */
  justify-content: center; /* Added justify content center */
}

.close-button {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: rgb(0, 221, 255);
}

.close-button:hover {
  color: #ff0000; /* Change color on hover */
}

iframe {
  width: 100%; /* Ensure the iframe fills the container */
  height: 100%; /* Ensure the iframe fills the container */
  border: none; /* Remove border */
}

/* Media query for mobile screens */
@media only screen and (max-width: 600px) {
  .resume-popup-content {
    padding: 10px; /* Adjusted padding for smaller screens */
  }
}
