/* Global styles */
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  color: #333;
}

/* Styles for dark mode */
.App.dark-mode {
  background-color: #1e1e1e;
  color: #fff;
}

.App-content {
  flex: 1;
  box-sizing: border-box; /* Include padding in element's total width/height */
  overflow-y: auto; /* Add vertical scrollbar if content exceeds viewport */
}
